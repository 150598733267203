import { InputGroup, Text } from '@workos-inc/component-library';
import { ConnectionAttributeMapping } from 'components/attribute-mapping';
import { Card } from 'components/card';
import { Confirm } from 'components/confirm';
import { Img } from 'components/image';
import { DownloadInput } from 'components/input';
import { MetadataUrlStep } from 'components/sso/steps/saml/metadata-url-step';
import { ConnectionStepProps } from 'interfaces/step-props';
import React, { FC } from 'react';

export const AdfsConfigureRelyingPartyTrust: FC<
  Readonly<ConnectionStepProps>
> = ({ connection, onNextStep: handleNextStep }) => (
  <>
    <Text>Open the AD FS Management console.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/a595dd78-07ff-4419-9675-af2e4a3434e6.png"
      width={1186}
    />

    <Text>Click “Relying Party Trusts” on the left sidebar.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/d6664d66-8c54-4689-8ed9-2e7883ecc272.png"
      width={1186}
    />

    <Text>
      Open the “AD FS Relying Party Trust Wizard” by clicking “Add Relying Party
      Trust...” on the right sidebar.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/1aaf2490-580f-4930-91b4-258326b751c3.png"
      width={1186}
    />

    <Text>Select “Claims aware” and then “Start”.</Text>

    <Text>Download the provided Metadata file from WorkOS.</Text>

    <DownloadInput
      filename="metadata.xml"
      label="Your SP Metadata File"
      value={connection?.saml_sp_metadata_url}
    />

    <Text>
      Select “Import data about the relying party from a file,” then select the
      SP Metadata file you downloaded, then click “Next”.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/9cf7ee4f-09d8-4dcc-ab92-29732ca3c691.png"
      width={1186}
    />

    <Text>Select “Permit everyone” and then “Next”.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/94d90815-ef73-4cf5-9a8e-9b85108163d3.png"
      width={1186}
    />

    <Confirm
      buttonText="Continue to Next Step"
      label="I’ve selected “Permit everyone” and clicked on “Next”."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsAccessPolicy: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>
      Click the “Endpoints” tab and confirm that the “SAML Assertion Consumer
      Endpoints” matches the URL below and click “Next”.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/c0c58966-3656-4079-b9c4-1ed01e2d2412.png"
      width={1186}
    />

    <Card>
      <InputGroup
        readOnly
        id="saml_acs_url"
        label="Confirm this URL matches the SAML Assertion Consumer Endpoint"
        name="saml_acs_url"
        value={connection?.saml_acs_url}
      />
    </Card>

    <Text>
      Select “Configure claims issuance policy for this application” and
      “Close”.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/a786ee79-750e-464f-ad4c-bdf685a7aec0.png"
      width={1186}
    />

    <Confirm
      label="I’ve finished and closed the Relying Party Trust Wizard."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsClaimsPolicy: FC<Readonly<ConnectionStepProps>> = ({
  connection,
  onNextStep: handleNextStep,
}) => (
  <>
    <Text>Click “Add Rule” in the “Edit Claims Issuance Policy” window.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/b0ce3aa1-5a5c-498a-8b40-f9297ed03a29.png"
      width={1186}
    />

    <Text>Select “Send LDAP Attributes as Claims” and then “Next”.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/753196aa-ebd0-4456-a961-4faacbfddbd2.png"
      width={1186}
    />

    <Text>Submit “Attributes” as “Claim rule name:”</Text>

    <Text>Select “Active Directory” as “Attribute Store”</Text>

    <Text>Fill in the following Attribute mappings:</Text>

    <ConnectionAttributeMapping connectionType={connection?.type} />

    <Text>Click “Finish”.</Text>

    <Img
      className="my-8"
      height={853}
      src="/images/e835b332-47de-43e5-a34d-0031395dee9c.png"
      width={1186}
    />

    <Confirm
      label="I’ve finished and closed the claims issuance policy."
      onClick={handleNextStep}
    />
  </>
);

export const AdfsUploadMetadata: FC<Readonly<ConnectionStepProps>> = (
  connectionStepProps,
) => (
  <MetadataUrlStep
    {...connectionStepProps}
    idpEntityIdPlaceholder="https://sso.adfs.com/12345abc..."
    idpSsoUrlPlaceholder="https://adfs.test.com/adfs/ls..."
    metadataUrlPlaceholder="https://SERVER/federationmetadata/2007-06/federationmetadata.xml"
  >
    <Text>
      Next you will want to obtain the Metadata URL from your ADFS server. ADFS
      publishes its metadata to a standard URL by default:
      (https://SERVER/federationmetadata/2007-06/federationmetadata.xml) where
      "SERVER" is your federation service FQDN. You can also find your ADFS
      Federation Metadata URL through the AD FS Management in "AD FS -{'>'}{' '}
      Service -{'>'} Endpoints" and navigate to the Metadata section.
    </Text>

    <Img
      className="my-8"
      height={853}
      src="/images/f9c91a23-847c-4032-9bbb-888d071db27d.png"
      width={1186}
    />

    <Text>
      Upload the Metadata URL and the connection will be verified and linked!
    </Text>
  </MetadataUrlStep>
);
